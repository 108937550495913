
import en from "vuetify/lib/locale/en";

export default {
  ...en,
  time: "Monday to Friday | 09:00 - 18:00",
  telephone: "+49 30 555 7053 0",
  fax: "+49 30 555 7053 99",
  address: "Danziger Straße 104 | 10405 Berlin",
  addressTwoLine: "Danziger Straße 104 \n 10405 Berlin",
  email: "service@medexo.com",
  next: "Next",
  prev: "Back",
  more: "Further information",
  map: {
    text: "To view a map of our location you must accept cookies.",
    button: "Accept",
  },
  cookie: {
    title: "This website uses cookies",
    text: "We use cookies to personalize content and ads.\nIncluding preference cookies, which allow a website to remember information that affects the way a website behaves or looks, such as your preferred language.",
    yes: "Accept",
    essential: "Essential only",
    no: "Reject",
  },
  appbar: {
    about: "About Us",
    contact: "Contact",
    home: "Home",
    secondopinionInfo: "Your second opinion",
    secondopinionSteps: "How it works",
    secondopinionCostCheck: "Cost Coverage",
    secondopinionExperts: "Your Specialists",
    secondopinionFaq: "FAQ",
    secondopinionDocuments: "Documents & Forms",

    shortopinionInfo: "Our Platform",
    shortopinionBenefits: "Why Promedicly?",
    shortopinionCustomers: "Customers & Partners",
  },
  bottomsheet: {
    imprint: "Imprint",
    generalTerms: "General terms and conditions of business",
    privacyPolicy: "Privacy Policy"
  },
  home: {
    secondopinion: "Second opinion",
    secondopinionDetail: "Assessment of an initial diagnosis by an independent physician.",
    shortopinion: "Medical assessment &\n Invoice audit",
    shortopinionDetail: "Commission various claims inquiries on one platform.\nSimple and efficient.",
    login: "Login",
    register: "Your second opinion",
    registerShort: "Your second opinion",
    more: "Further information",
  },
  about: {
    title: "About us",
    title2: '"Medexo" stands for Medical Experts Online. ',
    text: "We are an emerging company located in the heart of Berlin, dedicated to the mission of sustainably improving patient care in Germany. Since 2012, we have been pioneers in digital claims assessment for insurance, mediating second opinions and medical evaluations between policyholders of various health insurance providers and experts. We not only assist private health insurance companies but also support courts and other institutions.\n\nOur top priority is the welfare of the policyholder, both as a customer within the insurance system and as a patient within a guideline-driven and evidence-based healthcare system. Our goal is to facilitate people's access to medically necessary treatments and prevent unnecessary medical interventions.\n\nOur approach is transparent and independent, and so are our experts. We emphasize meticulous attention to detail in crafting assessments and in the design of our purpose-built software platform, enabling digital collaboration among the various stakeholders. We place significant emphasis on compliance with the General Data Protection Regulation (GDPR) right from the outset (Privacy by Design).\n\nWe see ourselves as partners to our customers and highly value active dialogue to collectively drive the evolution of our platform, creating a simple and secure exchange platform for all participants in the healthcare market.",
    partner: {
      profession: "Managing Partner"
    },
    experience: {
      "1": {
        "1": "More Than",
        "2": "20,000 medical assessments",
      },
      "2": {
        "1": "Since 2016",
        "2": "Proprietary developed software platform",
      },
      "3": {
        "1": "Since 2012",
        "2": "Experience in telemedicine processes"
      },
      "4": {
        "1": "Comprehensive",
        "2": "Expert network"
      }
    },
    team: {
      profession: {
        "1": "IT Lead",
        "2": "Software Developer",
        "3": "Working Student",
        "4": "Working Student",
        "5": "Doctor",
        "6": "Head of Operations",
        "7": "Case Manager",
        "8": "Assistant Dentist",
      },
    }
  },
  contact: {
    title: "How to contact us",
    time: "We are available for you from Monday till Friday, 9:00 am - 6:00 pm."
  },
  experts: {
    title: "Your Specialists",
    subtitle1: "Medexo exclusively collaborates with recognized and independent experts.\n All of them have years of experience and are highly respected and accepted in their respective fields.",
    subtitle2: "We regularly review our network of experts based on various parameters in different categories to ensure the quality of our network.",
    education: "Education",
    education0: "Specialization",
    education1: "Habilitation",
    education2: "(former) management position",
    education3: "Further training license",
    societies: "Science and awards",
    societies0: "Expert list",
    societies1: "Publications",
    societies2: "Congress Presidium",
    societies3: "Research prizes",
    reviewer: "Experts and guideline membership",
    reviewer0: "Court expert",
    reviewer1: "Over 5 years of experience as an expert",
    reviewer2: "Member AWMF",
  },
  shortopinion: {
    info: {
        text1: "Software platform for insurances",
        text2: "For the commissioning of medical assessments, claims management, and invoice auditing.\nGet a timely assessment from our experienced assessors.",
        card1: {
          title: "Your needs",
          text1: "Increased efficiency through tailor-made claims management",
          text2: "Swift assessment in compliance with data protection",
          text3: "Simple processes",
          text4: "Effective communication",
          text5: "Resilient medical assessment of complex topics",
          text6: "One point of contact for all claims cases",
        },
        card2: {
          title: "Our service",
          text1: "All questions",
          text2: "All documents",
          text3: "All departments",
          text4: "All assessments",
          text5: "All billing types",
          additional: "Everything from a single source"
        }
    },
    benefits: {
      title: "Why Promedicly?",
      title1: "1. Easy to use via internet browser without installation",
      text1: "Quick project start;Independent of your own IT infrastructure;Ideal for home office and hybrid working",
      title2: "2. User-friendly interface",
      text2: "Employees achieve quick success even without extensive computer experience;Only a few clicks to submit the order;The status of the case can be viewed at any time, ensuring maximum transparency in communication with policyholders",
      title3: "3. Data privacy",
      text3: "Login with 2-factor authentication;Compliant with data protection regulations;Integrated ISMS (Information Security Management System);External data protection officer;Software subjected to multiple penetration tests",
      title4: "4. You're in control",
      text4: "Want custom settings? - Absolutely!;Want the documents automatically redacted? - Absolutely!;Want us to handle the redaction? - Absolutely! (with a data processing agreement)",
    },
    customers: {
      title: "Customers & Partners",
      title1: "customers",
      title2: "partner"
    }
  },
  secondopinion: {
    info: {
      title: "What is a second opinion?",
      text: "A second opinion is the evaluation of an initial diagnosis by an independent physician. This includes an assessment of whether surgery is appropriate in the given case or if an alternative treatment would be more suitable. Through Medexo, patients can conveniently request and receive a second opinion over the internet. We forward the incoming case to a specialized expert in our network and then translate the expert's report into layman-friendly language. \n\nSecond opinions are always a good idea when a patient has concerns about whether the treatment recommended by their doctor is truly necessary and advisable. Especially before significant decisions, such as choosing chemotherapy, seeking a second opinion is recommendable.",
      title2: "Our service",
      text2: "Specially tailored questionnaire for your specific issue;Objective opinion from an expert on whether surgery is appropriate in your case;Layman-friendly assessment of the initial diagnosis and treatment recommendation;Many health insurance providers already trust Medexo's service",
      card1: {
        title: "What is a second opinion?",
        text: "Review of diagnosis and therapy recommendation;Recommendation for action;Extra easy to understand",
        more: "Further information",
      },
      card2: {
        title: "Who provides the second opinion?",
        text: "Renowned doctors from Germany;Experts with great experience in the relevant field;Independence through prohibition of further treatment",
        more: "Your Doctors",
      }
    },
    steps: {
      title: "In 3 steps to a second opinion",
      step1_title: "1. Capture your current condition",
      step1_text: "The first step involves completing our questionnaire and compiling your medical records.\n\nThis is necessary so that our experts can create their second opinions based on complete medical information. ",
      step2_title: "2. Submit Documents",
      step2_text: "After you have digitally or physically (via mail) submitted your data to us, we pass it on to the expert responsible for the specific medical field. After a thorough examination of your case, the expert creates a comprehensive second opinion.",
      step3_title: "3. Receive your Second Opinion",
      step3_text: "Once the expert has completed the evaluation, you will receive your second opinion within a few days. You can conveniently download the second opinion in your user account. Alternatively, we can also send it to you by mail."
    },
    costCheck: {
      title1: "Cost Coverage",
      title2: "Many health insurers cover the costs",
						additional: "Some procedures maybe excluded. *",
      button: "Check now",
      costBearer: "Select insurance",
      costBearerFilled: "Insurance: ",
      subject: "Select department",
      subjectFilled: "SubjectField: ",
      finish: "Result",
      true: "The cost of a second opinion is covered by your health insurer!",
      register: "Register now",
      false: "The costs for a second opinion are NOT covered by your health insurer!",
      help: "Please contact your health insurance provider."
    },
    faq: {
      title: "Frequently Asked Questions",
      question1: "How long does it take to get a second opinion?",
      answer1: "Once we have received all medical documents in full, the second opinion will be generated within a maximum of 10 business days. In urgent cases, a second opinion can be provided in a shorter period. Please contact us in this case by phone. However, an additional fee is applicable for expedited service, as our specialist will need to work significantly faster on your case.",
      question2: "What if I have more questions after receiving the second opinion?",
      answer2: "We highly value satisfactory customer support throughout the entire period before, during, and after receiving the medical second opinion. If you have any questions after receiving the second opinion, our medical customer service and our experts are available for further clarification.",
      question3: "Aren't remote diagnoses illegal?",
      answer3: "We do not provide remote diagnoses. The second opinions from our experts are an excellent complement to an in-person doctor visit, but they do not replace it. The specialist does not conduct a remote diagnosis but provides an independent opinion on your immediate on-site treatment based on your medical data. Our work is therefore absolutely legal and contributes significantly to patient safety. A direct discussion of the second opinion with a local colleague and immediate follow-up treatment are prerequisites for a meaningful implementation of our experts' recommendations. With our one-year aftercare, we are here to assist you even after the second opinion regarding any questions about further treatment.",
      question4: "Are your second opinions admissible in court?",
      answer4: "In principle, our second opinions are intended for you as a patient and serve as assistance in your decision-making process. A forensic expert opinion involves significantly more effort and is not our focus.",
      question5: "What documents do you need?",
      answer5: "n general, we require a doctor's letter from your most recent visit relevant to your current inquiry, a questionnaire filled out by you, and relevant medical imaging of your: knee (X-ray MRI), back (MRI or CT), foot (X-ray), etc. If any documents are missing, we will inform you immediately and are happy to assist you in obtaining them.",
      question6: "How do I request my records from the doctor?",
      answer6: "Every doctor is obligated to grant their patient access to their medical records. Therefore, you can request copies, and there may be a nominal fee for this service. Note: Regardless of this second opinion, it is essential always to request your records from the doctor (in the event of practice closure, relocation, etc.). The doctor is obligated, upon request, to create copies or prints, provide them, and confirm that the issued documents are complete (§ 10 para. 2 sentence 2 MBO-Ä).",
      question7: "How do I submit my patient documents?",
      answer7: "After registration and completing the questionnaire, you can upload your documents (reports, X-rays, CT scans) to your personal area. Alternatively, you can simply send us all your documents by mail, and we will forward them to the relevant expert for you. All images and reports sent to us by mail will be returned to you after the second opinion is completed.",
      question8: "How secure are my data when I upload/send my documents to you?",
      answer8: "The protection of your data is our highest priority. The transfer of your documents is encrypted and secured through the SSL certificate. Additionally, we store all data encrypted and anonymized. If you send us your documents by mail, these security measures apply, and we will return your originals to you once you have received your second opinion.",
      question9: "Who has access to my data?",
      answer9: "You alone have access to your personal, password-protected area. Therefore, please keep your login credentials secure and do not share them with anyone else. The collection and processing of your personal data are solely for the purpose of answering and processing your second opinion request. We treat your data with strict confidentiality and in compliance with all legal regulations. Your data will not be disclosed to third parties, except to the respective expert entrusted with the response and processing of your second opinion request.",
      question10: "Can I choose a specific specialist to create my second opinion?",
      answer10: "We thoroughly review your second opinion request and select the most suitable specialist for your case. Requests for specific specialists will be considered whenever possible but cannot be guaranteed due to availability.",
      question11: "Why should I seek a second opinion from you rather than visit another outpatient doctor?",
      answer11: "Our experts are not generalists but carefully selected specialists with comprehensive expertise and extensive experience in their respective fields. Since the expert is only allowed to provide you with a second opinion and is not permitted to further treat you, there is no incentive for them to recommend surgery. Our expert deeply engages with your case, and you receive the second opinion within a few days, which an outpatient doctor can seldom guarantee due to the number of patients and long waiting times. The efficiency, independence and neutrality of our experts’ opinions are highly significant advantages of our offering.",
						question12: "The department or specialization is not displayed in the selection. Why is that?",
						answer12: "For legal reasons, we cannot offer some procedures. For more information, please visit:\n"
    },
    documents: {
      title: "Documents & Forms",
						contract: "Second opinion agreement",
						attorney: "Power of Attorney for Document Request",
      declaration: "Declaration of participation"
    }
  },
  imprint: {
    title: "Imprint",
    company: "Medexo - Medical Experts Online is a service of Medexo GmbH",
    partnerTitle: "Represented by the managing directors",
    partnerText: "Dr. med Jan-Christoph Log | CEO\nTim Niemeyer | CFO",
    registerTitle: "Register entry",
    registerText: "Registergericht: AG Charlottenburg\nRegister number: HRB 142224 B",
    taxTitle: "VAT identification number",
    taxText: "DE 283254108",
    responsibleTitle: "Responsible for the content according to § 55 para. 2 RStV",
    responsibleText: "Dr. med. Jan-Christoph Loh\nDanziger Straße 104\n10405 Berlin",
    dataProtectionTitle: "Data Protection Officer",
    dataProtectionText: "Medexo GmbH\nPrivacy Officer\nDanziger Straße 104\n10405 Berlin\nndatenschutz@medexo.com",
    disclaimerTitle: "Disclaimer",
    disclaimerSubtitle1: "Liability for content",
    disclaimerText1: "The contents of our pages were created with the utmost care. However, we cannot guarantee the accuracy, completeness and timeliness of the content. As a service provider, we are responsible for our own content on these pages in accordance with § 7 para.1 TMG under the general laws. According to §§ 8 to 10 TMG, however, we are not obligated as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information under the general laws remain unaffected. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of any such infringements, we will remove this content immediately.",
    disclaimerSubtitle2: "Liability for links",
    disclaimerText2: "Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking. However, a permanent control of the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. If we become aware of any legal violations, we will remove such links immediately.",
    disclaimerSubtitle3: "Copyright liability",
    disclaimerText3: "The content and works on these pages created by the site operators are subject to German copyright law. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.",
    grafikTitle: "Graphics, logos & images on this page are from:",
    grafikText: "Karolina Grabowska - Pexels \n fauxels - Pexels \n Pixabay - Pexels"
  },
  generalTerms: {
    title: "AGB"
  },
}
